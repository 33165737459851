.hide-scrollbar {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease;
	animation-duration: 1s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: .5;
	}
	100% {
		opacity: 1;
	}
}